import { Controller } from 'react-hook-form'
import Select from 'react-select'

import {
  Checkbox,
  FormControl,
  Input,
  InputSize,
  Label,
  Message,
  MessageTypes
} from '../../../components'
import { inputClasses, reactSelectStyle, validateEmail } from '../../../utils'

interface IManageMemberModalForm {
  register: any
  errors: any
  control: any
  disabledInputs?: boolean
  autoFocus?: boolean
  watchSpecificAppsRole: any
  applications: any
  adminRoleChecked: boolean
}

export const ManageMemberModalForm: React.FC<IManageMemberModalForm> = ({
  register,
  errors,
  control,
  disabledInputs,
  autoFocus,
  watchSpecificAppsRole,
  applications,
  adminRoleChecked
}) => {
  return (
    <>
      <FormControl>
        <Label text="First Name" required={!disabledInputs} />
        <Input
          {...register('firstName', {
            required: 'First name is required',
            validate: {
              notValidName: (value: string) =>
                !!value.trim() || 'First name is required'
            }
          })}
          error={!!errors.firstName && errors.firstName.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          disabled={disabledInputs}
          autoFocus={autoFocus}
          maxLength={40}
        />
      </FormControl>

      <FormControl>
        <Label text="Last Name" required={!disabledInputs} />
        <Input
          {...register(
            'lastName',
            !disabledInputs && {
              required: 'Last name is required',
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || 'Last name is required'
              }
            }
          )}
          error={!!errors.lastName && errors.lastName.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          disabled={disabledInputs}
        />
      </FormControl>

      <FormControl>
        <Label text="Email" required={!disabledInputs} />
        <Input
          {...register('email', {
            required: 'Email is required',
            validate: (value: string) =>
              validateEmail(value) || 'Email field is not valid'
          })}
          error={!!errors.email && errors.email.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          type="email"
          disabled={disabledInputs}
          maxLength={320}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="Roles.SuperAdmin"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Admin"
              id="admin"
              description="Read and write access to all resources in the dashboard."
              checked={value || false}
              inputRef={ref}
              onChange={onChange}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="Roles.EditorSpecificApps"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Editor - Specific Apps"
              id="editor-specific-apps"
              checked={adminRoleChecked || value || false}
              inputRef={ref}
              onChange={onChange}
              disabled={adminRoleChecked}
            />
          )}
        />

        <div className="pl-7">
          <Controller
            control={control}
            name="applications"
            rules={{
              required: watchSpecificAppsRole
                ? 'One or more clients are required'
                : false
            }}
            render={({ field: { onChange, value, ref } }: any) => (
              <Select
                ref={ref}
                isDisabled={!watchSpecificAppsRole || adminRoleChecked}
                value={value}
                options={applications || []}
                getOptionLabel={(x) => x.clientName}
                getOptionValue={(x) => x.clientId}
                isSearchable
                isMulti
                menuShouldBlockScroll
                classNamePrefix="porta-react-select"
                menuPosition="fixed"
                onChange={onChange}
                className="text-sm multiple-select"
                placeholder="Select Application(s)"
                styles={reactSelectStyle}
              />
            )}
          />

          {errors.applications && watchSpecificAppsRole && (
            <div className="mt-2">
              <Message
                message={errors.applications.message}
                type={MessageTypes.error}
              />
            </div>
          )}
        </div>
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="Roles.EditorUsers"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Editor - Users"
              id="editor-users"
              description="User Management operations (create, delete, block, unblock, reset MFA, reset password, update metadata, assign roles, etc.) and access to logs."
              checked={adminRoleChecked || value || false}
              inputRef={ref}
              onChange={onChange}
              disabled={adminRoleChecked}
            />
          )}
        />
      </FormControl>
    </>
  )
}
