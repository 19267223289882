import { FC } from 'react'

//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { cx } from '../../utils'

interface IInfoTip {
  className?: string
  title: string
  description: string
}

export const InfoTip: FC<IInfoTip> = ({ className, title, description }) => {
  return (
    <div className={cx(['text-primary-secText flex align-end', className])}>
      <div className="flex-shrink-0	mr-2 relative top-0.5 w-5 h-5">
        <Icon name="Info" size={20} />
      </div>

      <div>
        <p className="font-bold text-sm">{title}</p>
        <p className="text-xs">{description}</p>
      </div>
    </div>
  )
}
