import { FC } from 'react'
import ReactPagination, { ReactPaginateProps } from 'react-paginate'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { cx } from '../../utils'

export interface PaginationProps {
  totalCount: number
  pageSize: number
  page: number
  onPageChange: ReactPaginateProps['onPageChange']
  length?: number
  viewingOf?: boolean
  className?: string
}

export const Pagination: FC<PaginationProps> = ({
  totalCount,
  pageSize,
  page,
  onPageChange,
  length,
  viewingOf = true,
  className,
}) => {
  if (!totalCount || !length) return null

  return (
    <div
      className={cx([
        'flex xl:items-center xl:flex-row flex-col items-start text-primary-tertText text-sm leading-5',
        className,
      ])}
    >
      <ReactPagination
        containerClassName="flex items-center"
        pageCount={totalCount / pageSize}
        forcePage={page - 1 || 0}
        onPageChange={onPageChange || undefined}
        previousLabel={
          <div className="w-4 h-4">
            <Icon name="ArrowLeft" size={18} />
          </div>
        }
        nextLabel={
          <div className="w-4 h-4">
            <Icon name="ArrowRight" size={18} />
          </div>
        }
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousClassName="flex items-center"
        nextClassName="flex items-center"
        pageClassName="flex items-center justify-center rounded select-none mx-1 hover:text-primary transition"
        pageLinkClassName="focus:outline-none py-0.5 px-2 inline-block rounded focus:shadow-outlinePrimary"
        activeLinkClassName="active:bg-primary-hover"
        activeClassName="text-white bg-primary border border-primary text-sm font-medium active:bg-primary-hover hover:text-white"
        // breakLabel={null}
        breakClassName="px-2"
        previousLinkClassName="flex items-center justify-center focus:text-primary-mainText select-none hover:text-primary-mainText rounded focus:shadow-outlinePrimary focus:outline-none transition inline-block"
        nextLinkClassName="flex items-center justify-center ml-1 focus:text-primary-mainText select-none hover:text-primary-mainText focus:shadow-outlinePrimary focus:outline-none transition inline-block rounded"
      />
      {viewingOf && (
        <div className="select-none ml-3 text-primary-secText xl:mt-0 mt-2">
          Viewing {pageSize * (page || 1) - (pageSize - 1)} to{' '}
          {pageSize * (page || 1) - (pageSize - length)} of {totalCount} Items
        </div>
      )}
    </div>
  )
}
