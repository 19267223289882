import { FC } from 'react'

import { Paragraph } from '..'
import { BtnSize, BtnStyle, BtnType, Button } from '../../components'
import { cx, primaryBtnClasses, warningBtnClasses } from '../../utils'

interface IButtonSection {
  className?: string
  title: string
  description?: string
  btnText: string
  primary?: boolean
  onClick?: () => void
  lastUpdated?: string
  disabled?: boolean
  loading?: boolean
  inlineButtonSection?: boolean
}

export const ButtonSection: FC<IButtonSection> = ({
  className,
  title,
  description,
  btnText,
  primary,
  onClick,
  lastUpdated,
  disabled,
  loading,
  inlineButtonSection
}) => {
  return (
    <div className={cx([className])}>
      {inlineButtonSection ? (
        <div>
          <p className="text-sm text-red-900 font-medium">{title}</p>

          <Paragraph className="mt-1 text-warning-hover" noMargin>
            {description}
          </Paragraph>
        </div>
      ) : (
        <>
          <p className="text-sm text-primary-mainText font-medium">{title}</p>

          <Paragraph className="mb-4 mt-1" noMargin>
            {description}
          </Paragraph>
        </>
      )}

      {primary ? (
        <>
          <Button
            type="button"
            btnSize={BtnSize.normal}
            btnType={BtnType.primary}
            className={cx(['flex items-center', primaryBtnClasses])}
            onClick={onClick}
            disabled={disabled}
          >
            {loading && (
              <img
                src={require('@/assets/image/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}

            {btnText}
          </Button>
          {!!lastUpdated && <Paragraph>Last sent: {lastUpdated}</Paragraph>}
        </>
      ) : (
        <Button
          type="button"
          btnSize={BtnSize.normal}
          btnStyle={BtnStyle.warning}
          className={warningBtnClasses}
          onClick={onClick}
          disabled={disabled}
        >
          {btnText}
        </Button>
      )}
    </div>
  )
}
