import { FC, TrackHTMLAttributes } from 'react'
import { cx } from '../../utils'
export const Tr: FC<TrackHTMLAttributes<HTMLTableRowElement>> = ({
  className,
  ...rest
}) => (
  <tr
    className={cx([
      'hover:bg-gray-50 transition border-b last:border-b-0',
      className
    ])}
    {...rest}
  />
)
