import { cx } from './classnames'

export const primaryBtnClasses = cx([
  'sm:font-normal sm:transition sm:px-4 py-2',
  'hover:bg-primary-hover hover:border-primary-300',
  'focus:shadow-outlinePrimary',
  'active:bg-primary-pressed'
])

export const cancelBtnClasses = cx([
  'sm:font-normal sm:transition sm:border-primary-stroke sm:px-4 sm:text-primary-mainText bg-white py-2',
  'hover:border-gray-500',
  'focus:shadow-outlineGray',
  'active:shadow-outlineGray active:bg-gray-50'
])

export const warningBtnClasses = cx([
  'sm:font-normal sm:transition sm:px-4 py-2',
  'hover:bg-warning-hover hover:border-warning-300',
  'focus:shadow-outlineWarning focus:bg-warning-pressed',
  'active:bg-warning-pressed'
])

export const inputClasses =
  'text-blue-mainText transition focus:shadow-primary hover:border-gray-400 py-2'

export const textareaClasses =
  'resize-none sm:border-primary-stroke transition focus:shadow-primary hover:border-gray-400 text-blue-mainText py-2 px-3'

export const linkLikeBtnClasses =
  'rounded transition-opacity outline-none focus:outline-none text-center text-white bg-primary py-2 px-3 flex-1 sm:flex-none sm:transition-colors sm:px-4 hover:bg-primary-300 hover:border-primary-300 text-sm'
