import { Dispatch, SetStateAction } from 'react'

import { format } from 'date-fns'
import { ButtonSection } from '.'
import { Modal } from '../../../components'
import { useFetch, useToggle } from '../../../hooks'
// @ts-ignore
import { showToast } from '@nodus/utilities-front'

interface IUser {
  email: string
  firstName: string
  lastName: string
  resetPasswordLink: string
}

interface IResetPassword {
  user: IUser
  setUser: Dispatch<SetStateAction<IUser>>
}

export const ResetPassword: React.FC<IResetPassword> = ({ user, setUser }) => {
  const { toggle, visible } = useToggle()
  const { apiCall: resetPasswordLink, loading } = useFetch('post')

  const onResetPasswordLink = () => {
    resetPasswordLink(
      `/Users/SendResetPasswordLink?email=${user?.email}&firstName=${user?.firstName}`,
      {},
      (res) => {
        setUser({ ...user, resetPasswordLink: res })
        toggle()
        showToast('success', 'Password reset link was sent!')
      }
    )
  }

  // if it does not have password, send a boolean value to false
  return (
    <>
      <ButtonSection
        className="mb-6"
        title="Reset Password"
        description="Reset password will send a password reset link to this user's
    email address where the user will be required to change the
    password when they login."
        btnText="Reset password"
        onClick={toggle}
        primary
        lastUpdated={
          user?.resetPasswordLink
            ? format(new Date(user?.resetPasswordLink), 'MMMM dd, yyyy')
            : ''
        }
      />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Reset Password"
          onConfirmClick={onResetPasswordLink}
          confirmBtnText="Send Reset Password Link"
          loading={loading}
          withFooter
          blockOutsideClick
        >
          <>
            <p className="text-sm text-primary-mainText mb-4">
              Are you sure you want to reset{' '}
              <strong className="font-medium">
                {user?.firstName} {user?.lastName}
              </strong>{' '}
              password?
            </p>

            <p className="text-sm text-primary-mainText mb-4">
              A password reset link is sent to this user’s email address, the
              password is not automatically reset. The password reset link will
              expire 1 hour after it is sent.
            </p>
          </>
        </Modal>
      )}
    </>
  )
}
