import { ButtonSection, Modal } from '../../../components'
import { ModalSize } from '../../../components/modal'
import { useFetch, useToggle } from '../../../hooks'
// @ts-ignore
import { showToast } from '@nodus/utilities-front'

interface IBlockUser {
  isBlocked: boolean
  setUser: any
  user: {
    mfaEnrollmentVerification: any
    email: string
    firstName: string
    lastName: string
    id: string
  }
}

export const BlockUser: React.FC<IBlockUser> = ({
  user,
  isBlocked,
  setUser
}) => {
  const { toggle, visible } = useToggle()

  const { apiCall: blockUser, loading: blockUserLoading } = useFetch('post')
  const { apiCall: unBlockUser, loading: unBlockUserLoading } = useFetch('post')

  const onBlockUser = async () => {
    blockUser(`/Users/BlockUser?id=${user?.id}`, {}, () => {
      toggle()
      setUser({
        ...user,
        isBlocked: true
      })
      showToast('success', 'User account blocked successfully!')
    })
  }

  const onUnBlockUser = async () => {
    unBlockUser(`/Users/UnBlockUser?id=${user?.id}`, {}, () => {
      toggle()
      setUser({
        ...user,
        isBlocked: false
      })
      showToast('success', 'User account unblocked successfully!')
    })
  }
  return (
    <>
      <ButtonSection
        title={`${isBlocked ? 'Unblock' : 'Block'} user`}
        className="mb-6"
        description="The user will be blocked for logging into your applications."
        btnText={isBlocked ? 'Unblock' : 'Block'}
        onClick={toggle}
      />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          loading={blockUserLoading || unBlockUserLoading}
          modalSize={ModalSize.sm}
          blockOutsideClick
          withFooter
          confirmBtnText={isBlocked ? 'Unblock' : 'Block'}
          warningModal
          title={`${isBlocked ? 'Unblock' : 'Block'} user`}
          onConfirmClick={isBlocked ? onUnBlockUser : onBlockUser}
        >
          <p className="text-sm text-primary-mainText mb-4">
            This operation will {isBlocked ? 'unblock' : 'block'} any attempt of
            sign-in of{' '}
            <strong className="font-medium">
              {user?.firstName} {user?.lastName}
            </strong>
            ? Are you sure?
          </p>
        </Modal>
      )}
    </>
  )
}
