export const userIState = {
  id: null,
  userName: '',
  picture: '',
  firstName: '',
  lastName: '',
  identityProvider: '',
  lastLogin: null,
  email: '',
  emailConfirmed: false,
  phoneNumber: null,
  phoneNumberConfirmed: false,
  twoFactorEnabled: false,
  lockoutEnd: null,
  isBlocked: false,
  colorId: 0
}

export const usersIState = {
  users: [],
  pageSize: 10,
  totalCount: 0
}

// Roles
export const roleIState = {
  id: '',
  name: '',
  description: ''
}

export const rolesIState = {
  roles: [roleIState],
  pageSize: 10,
  totalCount: 0
}

// Logs
export const logIState = {
  id: 0,
  event: '',
  source: '',
  category: 0,
  subjectIdentifier: '',
  subjectName: '',
  subjectType: '',
  subjectAdditionalData: '',
  action: '',
  data: '',
  created: new Date()
}
