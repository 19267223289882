import { useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useParams } from 'react-router-dom'

import {
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalSize,
  Table,
  Td,
  Tr
} from '../../components'
import { Eye } from '../../components/Icons'
import { useCurrentPage, useFetch, useToggle } from '../../hooks'
import { cancelBtnClasses } from '../../utils'

export const UserConsentLog = () => {
  const [log, setLog] = useState()
  const { id: userId } = useParams()
  const { currentPage, handlePageChange } = useCurrentPage()

  const { toggle, visible } = useToggle()
  const {
    apiCall: getUserLogs,
    response: logs,
    loading,
    errors
  } = useFetch('get')

  useEffect(() => {
    getUserLogs(`/Log/ConsentLog?userId=${userId}&page=${currentPage}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: 'Consent Date' },
          { children: 'IP Address' },
          { children: 'Browser Agent' }
        ]}
        values={logs?.consentLogs || []}
        renderTr={(el) => {
          return (
            <Tr key={el.clientId}>
              <Td className="relative py-3" width={230}>
                {el?.consentDate
                  ? format(new Date(el?.consentDate), 'MMMM dd yyyy, hh:mm:ss')
                  : ''}
              </Td>
              <Td className="py-3" width={150}>
                {el?.ipAddress}
              </Td>
              <Td className="py-3">{el?.browserAgent}</Td>
              <Td align="right" className="pr-2">
                <button
                  type="button"
                  className="text-primary-secText group-hover:text-primary text-xs flex items-center hover:text-primary-mainText p-1 rounded focus:shadow-outlinePrimary"
                  title="View Details"
                  onClick={() => {
                    toggle()
                    setLog(el)
                  }}
                >
                  <Eye />
                  <span className="ml-1">View</span>
                </button>
              </Td>
            </Tr>
          )
        }}
        pagination={{
          pageSize: 10,
          totalCount: 10,
          onPageChange: handlePageChange,
          page: currentPage,
          length: logs?.consentLogs?.length
        }}
        emptyView={{ text: errors || 'No logs yet.' }}
      />

      {!!visible && (
        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title="Details"
        >
          <form>
            <ModalBody>
              <div className="rounded border border-stroke p-2 mt-8 overflow-auto max-w-full max-h-96">
                <pre className="text-xs text-primary-secText leading-7">
                  {JSON.stringify(log, null, 4)}
                </pre>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                btnType={BtnType.secondary}
                btnStyle={BtnStyle.neutral}
                btnSize={BtnSize.normal}
                type="button"
                onClick={toggle}
                className={cancelBtnClasses}
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}
