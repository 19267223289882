import { FC, useEffect } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  BtnSize,
  BtnStyle,
  Button,
  Card,
  CardSpace,
  CardStyle,
  DeleteSection,
  FormControl,
  Input,
  InputSize,
  Label,
  Modal,
  ModalSize,
  Textarea
} from '../../components'
import { ROLES_PATH } from '../../constants'
import { useUserContext } from '../../context/UserContext'
import { useFetch, useToggle } from '../../hooks'
import { IRole } from '../../interfaces'
import {
  cx,
  inputClasses,
  primaryBtnClasses,
  textareaClasses
} from '../../utils'
// @ts-ignore
import { showToast } from '@nodus/utilities-front'

export const RoleSettings: FC = () => {
  const { role, setRole } = useUserContext()
  const { toggle, visible } = useToggle()
  const navigate = useNavigate()
  const { apiCall: deleteRole, loading } = useFetch('delete')
  const { apiCall: updateRole, loading: updateRoleLoading } = useFetch('put')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm()

  useEffect(() => {
    reset(role)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const onDeleteRole = () => {
    deleteRole(`/Roles/${role?.id}`, {}, () => {
      toggle()
      navigate(ROLES_PATH)
      showToast('success', 'Role deleted successfully!')
    })
  }

  const onUpdateRole: SubmitHandler<any> = (data: IRole) => {
    updateRole(
      `/Roles`,
      data,
      () => {
        setRole(data)
        showToast('success', 'Role updated successfully!')
      },
      (error) => {
        showToast(
          'error',
          error?.response?.data?.errors['DuplicateRoleName'][0]
        )
      }
    )
  }

  return (
    <>
      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="my-6"
      >
        <h6 className="text-primary-mainText">{role?.name}</h6>
        <p className="text-sm text-primary-secText">{role?.description}</p>

        <form onSubmit={handleSubmit(onUpdateRole)}>
          <FormControl className="mt-8 pr-4 sm:w-1/3">
            <Label text="Name" required htmlFor="name" />
            <Input
              id="name"
              {...register('name', {
                required: 'Name is required'
              })}
              className={inputClasses}
              error={!!errors.name && errors.name.message}
              inputSize={InputSize.sm}
            />
          </FormControl>

          <FormControl className="pr-4 sm:w-1/3 mt-2">
            <Label text="Description" htmlFor="description" />
            <Textarea
              {...register('description')}
              className={textareaClasses}
              maxLength={3000}
            />
          </FormControl>

          <Button
            type="submit"
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx(['flex items-center', primaryBtnClasses])}
            disabled={!isDirty || updateRoleLoading}
          >
            {updateRoleLoading && (
              <img
                src={require('@/assets/image/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}
            Save
          </Button>
        </form>
      </Card>

      <Card cardStyle={CardStyle.bordered} cardSpace={CardSpace.xl}>
        <DeleteSection
          title="Delete role"
          message="This action will affect all group members and cannot be undone."
          onDelete={toggle}
          className="flex-col items-center p-4 rounded bg-opacity-10 bg-red"
        />
      </Card>

      {visible && (
        <Modal
          hide={toggle}
          visible={visible}
          title="Delete role"
          onConfirmClick={onDeleteRole}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={loading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{' '}
            <strong className="font-medium">{role?.name}</strong>?
          </p>
        </Modal>
      )}
    </>
  )
}
