import { FC, TdHTMLAttributes } from 'react'
import { cx } from '../../utils'
export const Td: FC<TdHTMLAttributes<HTMLTableCellElement>> = ({
  className,
  ...rest
}) => (
  <td
    align="left"
    className={cx(['text-sm px-3 py-4 leading-5 font-normal group', className])}
    {...rest}
  />
)
