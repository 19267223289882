import { useState } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import {
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  StaticTabs
} from '../../../components'
import { ModalSize } from '../../../components/modal'
import { ILog } from '../../../interfaces'
import { cancelBtnClasses, copyToClipboard } from '../../../utils'

interface IDeleteModal {
  visible: boolean
  toggle?: () => void
  log: ILog
}

interface ITabItem {
  data: any
  copyText: string
}

export const LogsDetailModal: React.FC<IDeleteModal> = ({
  visible,
  toggle,
  log
}) => {
  const [activeTab, setActiveTab] = useState<ITabItem>()

  const tabOptions = [
    {
      name: 'Detail',
      isActive: true
    },
    {
      name: 'Subject Detail',
      isActive: false
    },
    {
      name: 'Action Detail',
      isActive: false
    }
  ]

  const dataItems: ITabItem[] = [
    {
      data: log?.data,
      copyText: 'Copy Detail'
    },
    {
      data: log?.subjectAdditionalData,
      copyText: 'Copy Subject'
    },
    {
      data: log?.action,
      copyText: 'Copy Action'
    }
  ]

  const getActiveTab = (tab: number) => {
    setActiveTab(dataItems[tab])
  }

  return (
    <Modal
      visible={visible}
      hide={toggle}
      modalSize={ModalSize.md}
      title="Details"
    >
      <form>
        <ModalBody>
          <StaticTabs tabs={tabOptions} getActiveTab={getActiveTab}>
            {dataItems.map((item: ITabItem, index: number) => {
              return (
                <div tabIndex={index}>
                  <div className="rounded border border-stroke p-2 mt-8 overflow-auto max-w-full max-h-96">
                    <pre className="text-xs text-primary-secText leading-7">
                      {item.data}
                    </pre>
                  </div>
                </div>
              )
            })}
          </StaticTabs>
        </ModalBody>

        <ModalFooter className="justify-between">
          <button
            type="button"
            className=" py-2 px-3 flex items-center text-primary-secText hover:text-primary focus:outline-none"
            onClick={() =>
              copyToClipboard(activeTab?.data, 'JSON copied successfully!')
            }
          >
            <div className="mr-2 w-5 h-5">
              <Icon name="Copy" size={20} />
            </div>
            {activeTab?.copyText}
          </button>

          <Button
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            type="button"
            onClick={toggle}
            className={cancelBtnClasses}
          >
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
