//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useMounted } from '../../hooks'
import { cx } from '../../utils'

interface IBackButton extends NavLinkProps {
  className?: string
  to: string | any
  label?: string
}

export const BackButton: React.FC<IBackButton> = ({
  className,
  to,
  label = 'Back',
  ...props
}) => {
  const { mounted } = useMounted()

  return (
    <NavLink
      to={to}
      className={cx([
        'rounded transition bg-transparent text-primary-secText text-sm inline-flex font-medium items-center justify-center',
        'focus:outline-none hover:text-primary-mainText',
        className,
      ])}
      title="Back"
      {...props}
    >
      <span className="inline-block w-4 h-4">
        {mounted && <Icon name="ArrowLeftAlt" size={18} />}
      </span>

      <span className="ml-2">{label}</span>
    </NavLink>
  )
}
