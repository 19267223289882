import { ReactNode } from 'react'
import { cx } from '../../utils'

interface IMainContainer {
  id?: string
  className?: string
  children: ReactNode
}

export const MainContainer: React.FC<IMainContainer> = ({
  className,
  children
}) => {
  return (
    <div
      className={cx([
        'flex flex-col h-full mx-auto my-0 max-w-screen-xl',
        className
      ])}
    >
      {children}
    </div>
  )
}
