import { RouteProps } from 'react-router-dom'
import { MEMBERS_PATH, ROLES_PATH, USERS_PATH } from '../constants'
import { Members, Role, Roles, User, Users } from '../pages'
type BreadcrumbsPropsItem = {
  name?: string
  to?: string
}

enum Permission {
  admin = 'SuperAdmin',
  usersEditor = 'EditorUsers',
  appsEditor = 'EditorSpecificApps'
}

const { admin, usersEditor } = Permission

export interface RoutesProps extends RouteProps {
  name?: string
  crumbs?: BreadcrumbsPropsItem[]
  permissions?: Permission[]
}

export const applicationsRoutes: RoutesProps[] = [
  // Users
  {
    path: USERS_PATH,
    element: <Users />,
    name: 'Users',
    crumbs: [{ name: 'Users' }],
    permissions: [admin, usersEditor]
  },
  {
    path: `${USERS_PATH}/:id/*`,
    element: <User />,
    name: 'User',
    crumbs: [{ name: 'Users', to: `${USERS_PATH}` }, { name: 'User' }],
    permissions: [admin, usersEditor]
    // TODO: we remove Routes from nested components, and replace them like this
    // children: [
    //   {
    //     path: `user-details`,
    //     element: <UserDetails />,
    //   },
    // ],
  },
  //Roles
  {
    path: ROLES_PATH,
    element: <Roles />,
    name: 'Roles',
    crumbs: [{ name: 'Roles' }],
    permissions: [admin, usersEditor]
  },
  {
    path: `${ROLES_PATH}/:id/*`,
    // path: [`${ROLES_PATH}/:id/settings`, `${ROLES_PATH}/:id/users`],
    element: <Role />,
    name: 'Role',
    crumbs: [{ name: 'Roles', to: `${ROLES_PATH}` }, { name: 'Role' }],
    permissions: [admin, usersEditor]
  },
  {
    path: MEMBERS_PATH,
    element: <Members />,
    name: 'Members',
    crumbs: [{ name: 'Members' }],
    permissions: [admin]
  }
]
