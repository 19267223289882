import { useAuth } from 'oidc-react'

import { BtnSize, BtnStyle, Button } from '../components'
import { cx, primaryBtnClasses } from '../utils'

export const Page401 = () => {
  const auth = useAuth()

  const signOut = () => {
    auth.signOutRedirect()
  }

  return (
    <div className="error-page-wrapper w-full flex items-center justify-center">
      <div className="flex items-center justify-center flex-col max-w-screen-sm text-center">
        <img
          src={require('@/assets/image/error-indicator.png')}
          alt="Unauthorized"
          width={380}
        />
        <h1 className="mb-4 text-primary-mainText font-bold mt-10">
          401 - Unauthorized Access
        </h1>
        <h4 className="text-primary-mainText mb-12">
          You have attempted to access a page for which you are not authorized.
        </h4>

        <div className="flex">
          <Button
            type="button"
            onClick={signOut}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx([
              'inline-flex items-center align-middle',
              primaryBtnClasses,
            ])}
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  )
}
