import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { Outlet } from "react-router-dom";

import { BackButton, Loader, Tab, Tabs, TopDetails } from "../../components";
import { UserConsentLog, UserDetails, UserHistory, UserRoles } from "../";
import { useUserContext } from "../../context/UserContext";
import { premiumFeatureEnabled, SEO } from "../../utils";
import { MainContainer } from "../../components/layout/MainContainer";
import { USERS_PATH } from "../../constants";
import { ILocation } from "./interface";
import { UserAuthorizedApps } from "./UserAuthorizedApps";
import { UserDevices } from "./UserDevices";

export interface IUserItem {}

export const User: FC<IUserItem> = () => {
  const { state } = useLocation() as ILocation;

  const { setUserId, user, loading } = useUserContext();

  let { id } = useParams();

  useEffect(() => {
    SEO({
      title: "Porta - User Details",
    });

    setUserId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  const routes = [
    { path: "user-details", element: <UserDetails /> },
    { path: "user-history", element: <UserHistory /> },
    { path: "user-roles", element: <UserRoles /> },
    {
      path: "user-authorized-applications",
      element: <UserAuthorizedApps />,
    },
    { path: "user-devices", element: <UserDevices /> },
    { path: `user-consents`, element: <UserConsentLog /> },
  ];

  return (
    <MainContainer>
      <div>
        <BackButton to={USERS_PATH} className="mb-4" label="Back to Users">
          <></>
        </BackButton>

        <TopDetails
          index={user?.colorId}
          rounded
          imgUrl={user?.picture}
          title={`${
            !!user?.firstName || !!user?.lastName
              ? user?.firstName + " " + user?.lastName
              : user?.email
          }`}
          textToCopy={user?.id}
          copyTextLabel="user_id"
          isBlocked={user?.isBlocked}
        />
      </div>

      <Tabs
        routes={routes}
        wrapperClassname="mt-8 sm:mt-0 flex flex-1 flex-col"
      >
        <Tab
          statePath={{
            pathname: `user-details`,
            state: {
              index: state?.index,
              user,
            },
          }}
          label="Details"
        />
        <Tab
          statePath={{
            pathname: `user-history`,
            state: {
              index: state?.index,
              user,
            },
          }}
          label="History"
        />
        <Tab
          statePath={{
            pathname: `user-roles`,
            state: {
              index: state?.index,
              user,
            },
          }}
          label="Roles"
        />

        {premiumFeatureEnabled["userDevices"] === true && (
          <Tab
            statePath={{
              pathname: `user-devices`,
              state: {
                index: state?.index,
                user,
              },
            }}
            label="Devices"
          />
        )}

        {premiumFeatureEnabled["authorizedApplication"] === true && (
          <Tab
            statePath={{
              pathname: `user-authorized-applications`,
              state: {
                index: state?.index,
                user,
              },
            }}
            label="Authorized Applications"
          />
        )}

        <Tab
          statePath={{
            pathname: `user-consents`,
            state: {
              user,
            },
          }}
          label="User Consents"
        />
      </Tabs>

      <Outlet />
    </MainContainer>
  );
};
