import { ChangeEvent, useEffect, useState } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { BtnSize, BtnType, Button, PageHeadeline } from '../../components'
import Search from '../../components/form/Search'
import { useToggle } from '../../hooks'
import { SEO, cx, primaryBtnClasses, reactSelectStyle } from '../../utils'
import {
  InvitationsListView,
  ManageMemberModal,
  MembersListView
} from './components'

export const Members: React.FC = () => {
  const { toggle, visible } = useToggle()
  const [makeACall, setMakeACall] = useState<string>('false')

  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [invitations, setInvitations] = useState<any>({
    totalCount: 0,
    pageSize: 10,
    data: []
  })
  const [activeView, setActiveView] = useState({
    name: 'Members',
    id: 'members'
  })

  const listTypes = [
    { name: 'Members', id: 'members' },
    { name: 'Invitations', id: 'invitations' }
  ]

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value)
  }

  const handleSelectChange = (selected: any) => {
    setActiveView(selected)
  }

  useEffect(() => {
    SEO({
      title: 'Porta - Members'
    })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    params.append('activeView', activeView.id)
    navigate({ search: params.toString() })
  }, [navigate, activeView])

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setMakeACall((prev) => (prev = prev === 'true' ? 'false' : 'true'))
    }
  }

  const handleSearchBtnClick = () => {
    searchTerm !== '' &&
      setMakeACall((prev) => (prev = prev === 'true' ? 'false' : 'true'))
  }

  const handleClearInputBtn = () => {
    setSearchTerm('')
  }

  // let viewThatIsActive = new URLSearchParams(search).get("activeView");

  return (
    <div className="h-full flex flex-col">
      <PageHeadeline
        className="mb-10"
        title="Members"
        description="Add team members to your organization in the Porta Dashboard and assign them a specific role to allow them access to the Porta Dashboard features."
      />

      <div className="flex justify-between mb-6">
        <Button
          btnType={BtnType.primary}
          btnSize={BtnSize.normal}
          className={cx([
            'flex items-center whitespace-nowrap mr-4',
            primaryBtnClasses
          ])}
          onClick={toggle}
        >
          <span className="inline-block w-5 h-5">
            <Icon name="Plus" size="20" />
          </span>

          <span className="ml-2">Invite Member</span>
        </Button>

        <div className="flex">
          <Select
            onChange={handleSelectChange}
            defaultValue={activeView}
            value={activeView}
            options={listTypes}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            isSearchable
            menuPortalTarget={document.body}
            className="w-40 text-sm mr-4"
            classNamePrefix="porta-react-select"
            styles={reactSelectStyle}
          />

          <Search
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
            onClearInputBtn={handleClearInputBtn}
            onSearchBtnClick={handleSearchBtnClick}
            value={searchTerm}
            placeholder="Search..."
            className="h-full"
          />
        </div>
      </div>

      {activeView.id === 'members' ? (
        <MembersListView searchTerm={searchTerm} makeACall={makeACall} />
      ) : (
        <InvitationsListView
          makeACall={makeACall}
          searchTerm={searchTerm}
          invitations={invitations}
          setInvitations={setInvitations}
        />
      )}

      {visible && (
        <ManageMemberModal
          toggle={toggle}
          visible={visible}
          listView={invitations}
          setListView={setInvitations}
          editMode={false}
          setActiveView={setActiveView}
        />
      )}
    </div>
  )
}
