//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { copyToClipboard, cx } from '../../utils'

export interface ICopyText {
  text: string
  className?: string
  fontSize?: string
  iconSize?: number
  truncate?: boolean
  maxWidth?: string
  copyTextLabel?: string
  style?: object
}

export const CopyText: React.FC<ICopyText> = ({
  text,
  className,
  fontSize = 'text-sm',
  iconSize = 20,
  truncate = false,
  maxWidth = '',
  copyTextLabel,
  style,
  ...props
}) => {
  return (
    <div
      className={cx([
        fontSize,
        className,
        'flex items-center min-w-0 max-w-full',
      ])}
      {...props}
      style={style}
    >
      <span className="whitespace-nowrap">{copyTextLabel}:</span>
      <div
        className={cx([
          'p-1 bg-primary-stroke rounded flex items-center text-primary-mainText ml-2 min-w-0 md:max-w-sm max-w-full',
          fontSize,
          truncate && maxWidth,
        ])}
      >
        <span className={cx([truncate && 'truncate w-full'])} title={text}>
          {text}
        </span>
        <button
          className="ml-2 transition hover:text-primary focus:outline-none flex items-center"
          type="button"
          onClick={() =>
            copyToClipboard(text, copyTextLabel + ' copied to clipboard')
          }
        >
          <span className="inline-block w-4 h-4">
            <Icon name="Copy" size={iconSize} />
          </span>
        </button>
      </div>
    </div>
  )
}
