import { Dispatch, SetStateAction } from 'react'

import { format } from 'date-fns'

import { ButtonSection } from '../../../components'
import { useFetch } from '../../../hooks'
// @ts-ignore
import { showToast } from '@nodus/utilities-front'

interface IMfaMethod {
  tfaMethod: string
  contact: string
}

interface IUser {
  mfaEnrollmentVerification: Date
  email: string
  firstName: string
  mfaMethods: IMfaMethod[]
}

interface IEnrollmentVerification {
  user: IUser
  setUser: Dispatch<SetStateAction<IUser>>
}

export const EnrollmentVerification: React.FC<IEnrollmentVerification> = ({
  user,
  setUser
}) => {
  const { apiCall: sendEnrollmentVerification, loading } = useFetch('post')

  const onSendEnrollmentVerification = () => {
    sendEnrollmentVerification(
      `/Users/SendMfaInvitation?email=${user?.email}&firstName=${user?.firstName}`,
      {},
      (res) => {
        setUser({ ...user, mfaEnrollmentVerification: res })
        showToast('success', `Enrollment verification sent successfully!`)
      }
    )
    // setLoading(true);
    // sendEnrollmentVerificationService(user?.email, user?.firstName).then(
    //   (res) => {
    //     // console.log(res);
    //     setLoading(false);
    //     // TODO: response should return reset date
    //     // if (!!res)
    //     //   setUser({
    //     //     ...user,
    //     //     mfaEnrollmentVerification: res?.data,
    //     //   });
    //   }
    // );
  }

  // console.log("user mfa methods", user?.mfaMethods);

  return (
    <>
      <ButtonSection
        className="mb-6"
        title="Multi-Factor Authentication"
        description=" Prompt the user to set up their MFA method, by sending an enrollment
        verification."
        btnText="Send an enrollment verification"
        primary
        onClick={onSendEnrollmentVerification}
        disabled={loading}
        loading={loading}
        lastUpdated={
          user?.mfaEnrollmentVerification
            ? format(new Date(user?.mfaEnrollmentVerification), 'MMMM dd, yyyy')
            : ''
        }
      />
    </>
  )
}
