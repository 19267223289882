import { useEffect, useState } from 'react'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { NavLink } from 'react-router-dom'
import {
  Avatar,
  AvatarRounded,
  AvatarSize,
  Dropdown,
  DropdownOption,
  Modal,
  ModalSize,
  Status,
  Table,
  Td,
  ThreeDotsButton,
  Tr
} from '../../../components'
import { USERS_PATH } from '../../../constants'
import { useCurrentPage, useFetch, useToggle } from '../../../hooks'
import { IInvitedMember } from '../../../interfaces'
import { copyToClipboard } from '../../../utils'

interface IInvitationsListView {
  searchTerm: string
  invitations: any
  setInvitations: any
  makeACall?: string
}

export const InvitationsListView: React.FC<IInvitationsListView> = ({
  searchTerm,
  invitations,
  setInvitations,
  makeACall
}) => {
  const [selectedInvitation, setSelectedInvitation] = useState<IInvitedMember>()
  const {
    visible: deleteInvitationModalVisible,
    toggle: deleteInvitationModalToggle
  } = useToggle()
  const { currentPage, handlePageChange } = useCurrentPage()

  const {
    apiCall: getInvitations,
    response: invitationsResponse,
    loading
  } = useFetch('get')

  const { apiCall: deleteInvitation, loading: deleteInvitationLoading } =
    useFetch('post')

  useEffect(() => {
    getInvitations(
      `/Users/InvitationsListView?searchText=${searchTerm}&page=${currentPage}&pageSize=10`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, makeACall])

  useEffect(() => {
    setInvitations(invitationsResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationsResponse])

  const onDeleteInvitation = () => {
    deleteInvitation(
      `/Users/DeleteInvitation?code=${selectedInvitation?.code}`,
      {},
      () => {
        showToast('success', 'Member deleted successfully')
        deleteInvitationModalToggle()

        setInvitations({
          ...invitations,
          data: invitations?.data?.filter(
            (item: any) => item.userId !== selectedInvitation?.userId
          ),
          totalCount: invitations?.totalCount - 1
        })
      }
    )
  }

  const emptySectionText =
    invitations?.data?.length < 1 && searchTerm !== ''
      ? 'No invited member found.'
      : 'No invited members yet.'

  return (
    <>
      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: 'Name' },
          { children: 'Role' },
          { children: 'MFA' },
          { children: 'Actions', className: 'text-right' }
        ]}
        values={invitations?.data}
        loading={loading}
        renderTr={(el: IInvitedMember) => {
          return (
            <Tr>
              <Td>
                <NavLink
                  to={`${USERS_PATH}/${el?.userId}/user-details`}
                  className="group block"
                >
                  <div className="flex items-center">
                    <Avatar
                      imgUrl=""
                      text={`${el?.firstName}`}
                      size={AvatarSize.sm}
                      rounded={AvatarRounded['rounded-full']}
                      className="mr-4"
                      colourful
                      index={el.colorId || 0}
                    />

                    <div>
                      <p className="text-sm group-hover:text-primary transition">
                        {el?.firstName} {el?.lastName}
                      </p>
                      <p className="text-primary-secText text-xs">
                        {el?.email}
                      </p>
                    </div>
                  </div>
                </NavLink>
              </Td>
              <Td>
                {el?.roles?.map((role: string, index: number) => {
                  return (
                    <p className="mr-2 text-sm inline-block" key={index}>
                      {role === 'SuperAdmin' ? 'Admin' : role}
                      {index !== el?.roles?.length - 1 && ','}
                    </p>
                  )
                })}
              </Td>
              <Td>
                <Status enabled={el?.mfaEnabled} />
              </Td>
              <Td align="right">
                <Dropdown
                  width="w-48"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        withIcon
                        iconName="Copy"
                        label="Copy invitation link"
                        onClick={() =>
                          copyToClipboard(
                            el?.invitationLink,
                            'Invitation link copied successfully!'
                          )
                        }
                      />

                      <DropdownOption
                        withIcon
                        iconName="Close"
                        label="Remove invitation"
                        onClick={() => {
                          deleteInvitationModalToggle()
                          setSelectedInvitation(el)
                        }}
                      />
                    </>
                  }
                >
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </Tr>
          )
        }}
        pagination={{
          pageSize: invitations?.pageSize,
          totalCount: invitations?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: invitations?.data?.length
        }}
        emptyView={{ text: emptySectionText }}
      />

      {!!deleteInvitationModalVisible && (
        <Modal
          hide={deleteInvitationModalToggle}
          visible={deleteInvitationModalVisible}
          title="Delete Invitation"
          onConfirmClick={onDeleteInvitation}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={deleteInvitationLoading}
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{' '}
            <strong className="font-medium">
              {selectedInvitation?.firstName} {selectedInvitation?.lastName}
            </strong>
            ?
          </p>
        </Modal>
      )}
    </>
  )
}
