import { Dispatch, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
// @ts-ignore
import { showToast } from '@nodus/utilities-front'

import {
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  FormControl,
  InfoTip,
  Input,
  InputSize,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from '../../../components'
import { ModalSize } from '../../../components/modal'
import { useFetch } from '../../../hooks'
import { IUser, IUsersAll } from '../../../interfaces'
import {
  cancelBtnClasses,
  cx,
  handleErrors,
  inputClasses,
  primaryBtnClasses,
  userIState,
  validateEmail
} from '../../../utils'

interface IUserCreateModal {
  visible: boolean
  toggle: () => void
  setCurrentPage: any
  setResponse: Dispatch<IUsersAll>
  response: IUsersAll
}

export const UserCreateModal: React.FC<IUserCreateModal> = ({
  visible,
  toggle,
  setResponse,
  response
}) => {
  const [btnType, setBtnType] = useState<string>('')
  const { apiCall: createUser, loading } = useFetch('post')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setFocus
  } = useForm()

  useEffect(() => {
    reset(userIState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    toggle()
    reset()
  }

  const onCreateUser: any = (data: IUser, btnType: string) => {
    setBtnType(btnType)
    const userObj = {
      ...data,
      userName: data.userName || data.email
    }

    createUser(
      `/Users?firstName=${data.firstName}&lastName=${data.lastName}`,
      userObj,
      (res) => {
        setResponse({
          ...response,
          users: [
            {
              ...res,
              userName: data.userName || data.email,
              firstName: data?.firstName,
              lastName: data?.lastName,
              id: res?.id
            },
            ...response.users
          ]
        })

        reset()
        showToast('success', 'User created successfully!')

        if (btnType === 'create') toggle()
      },
      (error) => {
        handleErrors(error)
      }
    )
  }

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.md}
      title="Create User"
      blockOutsideClick
    >
      <form>
        <ModalBody>
          <FormControl>
            <Label required text="First Name" htmlFor="first-name" />
            <Input
              id="first-name"
              autoFocus
              {...register('firstName', {
                required: 'First name is required',
                validate: {
                  notValidName: (value: string) =>
                    !!value.trim() || 'First name is required'
                }
              })}
              error={!!errors.firstName && errors.firstName.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              maxLength={40}
            />
          </FormControl>

          <FormControl>
            <Label required text="Last Name" htmlFor="last-name" />
            <Input
              id="last-name"
              {...register('lastName', { required: 'Last name is required' })}
              error={!!errors.lastName && errors.lastName.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              maxLength={40}
              required
            />
          </FormControl>

          <FormControl>
            <Label required text="Email" htmlFor="email" />
            <Input
              id="email"
              {...register('email', {
                required: 'Email is required',
                validate: (value) =>
                  validateEmail(value) || 'Email field is not valid'
              })}
              error={!!errors.email && errors.email.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              type="email"
              maxLength={320}
            />
          </FormControl>

          <InfoTip
            title="Password"
            description="The new user activation email is sent to this person prompting user to set password by clicking on the verification link."
          />
        </ModalBody>

        <ModalFooter>
          <Button
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            className={cancelBtnClasses}
            type="button"
            onClick={closeModal}
            disabled={loading}
          >
            Cancel
          </Button>

          <Button
            type="button"
            onClick={() =>
              // TODO: Should check type
              // handleSubmit((data: IUser) =>
              handleSubmit((data: any) => onCreateUser(data, 'create'))()
            }
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx(['ml-4 flex items-center', primaryBtnClasses])}
            disabled={loading && btnType === 'create'}
          >
            {loading && btnType === 'create' && (
              <img
                src={require('@/assets/image/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}
            Create
          </Button>

          <Button
            type="button"
            onClick={() => {
              // TODO: Should check type
              // handleSubmit((data: IUser) =>
              handleSubmit((data: any) => onCreateUser(data, 'add-another'))()
              setFocus('firstName')
            }}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx(['ml-4 flex items-center', primaryBtnClasses])}
            disabled={loading && btnType === 'add-another'}
          >
            {loading && btnType === 'add-another' && (
              <img
                src={require('@/assets/image/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}
            Create and Add another
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
